import Button from "components/Shared/Button/Button";
import { useRouter } from "next/router";
import React from "react";

const Page404 = () => {
  const router = useRouter();

  return (
    <div className="page404">
      <img src="/static/images/image404.png" className="image404" alt="no page image" />
      <h1 className="headerText">404</h1>
      <h2 className="information">Hey captain! Looks like you're heading to a wrong planet!</h2>
      {typeof window !== "undefined" && (
        <strong className="bad-url">
          {window?.location?.hostname + "" + window?.location?.pathname}
        </strong>
      )}
      <Button
        name="Take me back to the homepage"
        primary
        onClick={() => router.push("/dashboard")}
      />
    </div>
  );
};

export default Page404;
